import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas"
import React, { useEffect } from "react"

const RiveLoader = props => {
  const { setRive, file, stateMachines, className, callbackFn } = props
  const { rive, RiveComponent } = useRive({
    src: file,
    stateMachines: stateMachines,
    automaticallyHandleEvents: true,
    onLoad: () => {
      callbackFn && callbackFn()
    },
    onLoadError: e => {
      console.log("RiveAnimationError")
    },
    autoplay: true,
    layout: new Layout({
      fit: Fit.Fill,
      alignment: Alignment.TopCenter,
    }),
  })
  useEffect(() => {
    setRive && setRive(rive)
  }, [rive])
  return (
    <div>
      <RiveComponent
        className={className}
        stateMachines="Scene"
        layout={
          new Layout({
            fit: Fit.Fill,
            alignment: Alignment.TopCenter,
          })
        }
      />
    </div>
  )
}

export default RiveLoader
