import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import {
  AccordionHeader,
  AccordionNode,
  AccordionPanel,
  AccordionWithHeader,
} from "react-accordion-with-header"
import "../../../styles/component/faq.scss"
import SVGIcon from "../SVGIcon"
import CarouselComponent from "../carousel-component"
import "./custom-accordian.scss"

const NewAccordianV3 = props => {
  const { data } = props

  const responsiveAll = {
    allScreen: {
      breakpoint: { max: 4000, min: 0 },
      items: 1.2,
      slidesToSlide: 1,
    },
  }
  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  const setSessStorage = id => {
    if (id) sessionStorage.setItem("pageBlockScrollId", id)
  }

  const DropdownContents = props => {
    let { el } = props
    return (
      <div className="position-relative mt32">
        <div className="link-box br10 position-relative d-flex">
          {
            <>
              {el.icon && (
                <div className="icon-wrapper">
                  <img
                    decoding="async"
                    className="icon"
                    src={el.icon?.url}
                    alt="icon"
                  />
                </div>
              )}
              <div className="position-relative">
                {el.isNew === "Yes" && (
                  <p className="tag position-absolute m-0">NEW</p>
                )}
                <p className="title font-roboto text-deep-purple fw-500 m-0">
                  {el.heading}
                  {props.rightArrow && (
                    <SVGIcon
                      name="hypertext-arrow-right"
                      className="arrow-right position-absolute"
                    />
                  )}
                </p>
                <p className="subtext m-0">{el.subText}</p>
              </div>
            </>
          }
        </div>
      </div>
    )
  }

  useEffect(() => {
    const getac = document.querySelectorAll(".wrap-qn-new")

    if (props.isVisible)
      for (let i = 0; i < getac.length; i++) {
        setTimeout(() => {
          const getclick = document.querySelectorAll(".accordion-new")
          getac[i].classList.add("accordAnimate")
          i == getac.length - 1 &&
            setTimeout(() => {
              getclick[0].click()
            }, i * 10)
        }, i * 100)
      }
  }, [props.isVisible])
  return (
    <AccordionWithHeader
      // firstOpen={true}
      onChange={(panels, state) => console.log(panels, state)}
      actionCallback={(panels, state) => {
        props.callback(state.active[0])
      }}
      className="myaccord"
      style={{ boxShadow: "none" }}
    >
      {data.map((q, i) => {
        return (
          <AccordionNode key={i} className="wrap-qn-new">
            <AccordionHeader
              className="accordion-new font-roboto"
              style={{
                padding: "24px",
              }}
            >
              {q.heading}
            </AccordionHeader>
            <AccordionPanel className="card-body-new" speed={200}>
              <div>
                <div className="content">
                  <p className="mb16">{q.subText}</p>
                  <Link to={q.link} className="hyperlink-arrow">
                    {q.childFooter}
                  </Link>
                </div>
                <div className="mob-content">
                  <CarouselComponent
                    responsive={responsiveAll}
                    swipeable
                    customButtonNew
                    status
                    partialVisbile
                    customButtonclass="home-carousel"
                  >
                    {q.featureNavigationLinks.map((el2, idx2) => {
                      if (idx2 < 4) {
                        return el2.link ? (
                          <div onClick={() => setSessStorage(el2.childHeader)}>
                            <Link to={el2.link}>
                              <DropdownContents el={el2} rightArrow />
                            </Link>
                          </div>
                        ) : (
                          <DropdownContents el={el2} />
                        )
                      }
                    })}
                  </CarouselComponent>
                  <div className="mt32">
                    <img src={q.image.url} className="w-100" alt={q.heading} />
                  </div>
                </div>
              </div>
            </AccordionPanel>
          </AccordionNode>
        )
      })}
    </AccordionWithHeader>
  )
}
export default NewAccordianV3
