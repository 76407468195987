import { EventType } from "@rive-app/react-canvas"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import RiveLoader from "../RiveLoader/RiveLoader"
import Buttons from "../button"

const IntegrationWheel = props => {
  const { dataOne, dataTwo } = props
  const [showIntegwheel, setShowIntegWheel] = useState(false)
  const [riveLoad, setRiveLoad] = useState()
  const [rive, setRive] = useState()

  useEffect(() => {
    window.addEventListener("scroll", handleVisibility)
    return function cleanup() {
      window.removeEventListener("scroll", handleVisibility)
    }
  }, [])

  const handleVisibility = () => {
    const integWheel = document.getElementById("integ_wheel_id")
    if (integWheel?.getBoundingClientRect()?.top < window.innerHeight / 2) {
      setShowIntegWheel(true)
    }
  }

  useEffect(() => {
    handleVisibility()
  }, [riveLoad])

  const onRiveEventReceived = riveEvent => {
    const eventData = riveEvent.data
    if (
      (eventData.name === "ButtonHover_01" ||
        eventData.name === "ButtonHover_02" ||
        eventData.name === "ButtonHover_03") &&
      eventData.properties["isHovering"]
    ) {
      document.body.style.cursor = "pointer"
    } else {
      document.body.style.cursor = "auto"
    }
  }

  useEffect(() => {
    if (rive) {
      rive.on(EventType.RiveEvent, onRiveEventReceived)
    }
  }, [rive])
  return (
    <section id="integ_wheel_id" className="integration-wheel">
      <Container className="Layout-container">
        <div className="text-center">
          <h2 className="font-roboto fw-800 mb24 title-1 down-to-up-5">
            {dataOne.cardName}
          </h2>
          <p className="p18 subtext mx-auto down-to-up-5 m-0">
            {dataOne.cardDescription}
          </p>
        </div>
        <div className="d-mob mt48">
          <Fade duration={1000}>
            <img src={dataOne.image[0].url} className="w-100" />
          </Fade>
        </div>
        <div className="d-dsk">
          <div className="wheel-height">
            {showIntegwheel ? (
              <RiveLoader
                setRive={setRive}
                stateMachines="State_Wheel"
                file={dataOne.image[1].url}
                className="rive-board text-center"
                callbackFn={() => setRiveLoad(true)}
              />
            ) : null}
          </div>
        </div>
        <Fade bottom duration={1000}>
          <div className="altv1 d-flex justify-content-center flex-wrap align-items-center integration-part">
            <h4 className="font-roboto fw-bold title-2">
              {dataTwo.cardName}
            </h4>
            <Buttons
              theme="secondary-new integ-btn"
              link={dataTwo.slug}
              text={dataTwo.cardDescription}
            />
          </div>
        </Fade>
      </Container>
    </section>
  )
}

export default IntegrationWheel
