// import CustomAccordion from "./accordian"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import TrackVisibility from "react-on-screen"
import Fade from "react-reveal/Fade"
import "../../styles/component/faq.scss"
import "../../styles/component/pricing-faq.scss"
import "../../styles/pages/affiliate.scss"
import "../../styles/pricing-new.scss"
import NewAccordianV3 from "./Accordian-new/custom-accordion-v3"
import SVGIcon from "./SVGIcon"

const AccordionV3 = props => {
  const { data } = props

  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])
  const navigationLinks = data.navigationLinks

  const [activeAccordion, setActiveAccordion] = useState(0)

  const callbackFun = data => {
    const accprevIndex = parseInt(sessionStorage.getItem("accprevindex"))
    data != null ? setActiveAccordion(data) : setActiveAccordion(accprevIndex)
    const getac = document.querySelectorAll(".wrap-qn-new")
    if (screenWidth >= 992) {
      getac[accprevIndex].style.background =
        "linear-gradient(270deg, rgba(168, 30, 43, 0.22) -12.9%, #8F0B17 100%)"
      getac[data != null ? data : accprevIndex].style.background = `white`
    }
    sessionStorage.setItem("accprevindex", data != null ? data : accprevIndex)
    setTimeout(() => {
      const getac = document.querySelectorAll(".wrap-qn-new")
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) &&
        data != null
      ) {
        window.scrollTo({
          top: getac[data].offsetTop - 120,
          behavior: "smooth",
        })
      }
    }, 500)
  }
  useEffect(() => {
    window.onunload = function() {
      sessionStorage.removeItem("accprevindex")
    }
    const checkPrev = () => {
      var accprevIndex = sessionStorage.getItem("accprevindex")
      if (accprevIndex === null) {
        sessionStorage.setItem("accprevindex", 0)
      }
    }
    checkPrev()
  }, [])

  const FadeChildren = props => {
    return (
      <Fade delay={100} bottom distance="30px">
        {props.children}
      </Fade>
    )
  }
  const DropdownContents = props => {
    let { el } = props
    return (
      <FadeChildren>
        <div className="position-relative">
          <div className="link-box br10 position-relative d-flex">
            {
              <>
                {el.icon && (
                  <div className="icon-wrapper">
                    <img
                      decoding="async"
                      className="icon"
                      src={el.icon?.url}
                      alt="icon"
                    />
                  </div>
                )}
                <div className="position-relative">
                  {el.isNew === "Yes" && (
                    <p className="tag position-absolute m-0">NEW</p>
                  )}
                  <p className="title font-roboto text-deep-purple fw-500 m-0">
                    {el.heading}
                    {props.rightArrow && (
                      <SVGIcon
                        name="hypertext-arrow-right"
                        className="arrow-right position-absolute"
                      />
                    )}
                  </p>
                  <p className="subtext m-0">{el.subText}</p>
                </div>
              </>
            }
          </div>
        </div>
      </FadeChildren>
    )
  }

  const setSessStorage = id => {
    if (id) sessionStorage.setItem("pageBlockScrollId", id)
  }

  return (
    <>
      <section className="accordionV3 d-flex justify-content-center">
        <div
          className="layout-width outer d-flex justify-content-center"
          id="bgchange"
        >
          <div className="Layout-container">
            <Row className="justify-content-between">
              <Col lg={4}>
                <div className="left mb24">
                  <div className="altv3 p16">
                    <Fade bottom duration="1500">
                      <h1 className="heading font-roboto mb16 font-white">
                        {data.where}
                      </h1>
                      <p className="p16 font-white mb30">
                        {data.dropdownHeader}
                      </p>
                    </Fade>
                  </div>
                  <TrackVisibility
                    once
                    children
                    partialVisibility={true}
                    offset={-200}
                  >
                    <NewAccordianV3
                      data={navigationLinks}
                      callback={callbackFun}
                    />
                  </TrackVisibility>
                </div>
              </Col>
              <Col lg={7}>
                <div className="d-dsk right">
                  <div className="position-relative d-flex align-items-center justify-content-between h100 flex-column">
                    <div className="d-flex flex-wrap justify-content-center row-gap-24 col-gap-24">
                      {navigationLinks[
                        activeAccordion
                      ].featureNavigationLinks.map((el2, idx2) => {
                        if (idx2 < 4) {
                          return el2.link ? (
                            <div
                              onClick={() => setSessStorage(el2.childHeader)}
                            >
                              <Link to={el2.link}>
                                <DropdownContents el={el2} rightArrow />
                              </Link>
                            </div>
                          ) : (
                            <DropdownContents el={el2} />
                          )
                        }
                      })}
                    </div>
                    <div
                      className="position-relative"
                      style={{ minHeight: "470px" }}
                    >
                      {navigationLinks.map((item, i) => (
                        <div
                          className={`image-wrapper ${
                            activeAccordion === i
                              ? "scaleFadeAnime"
                              : "fade-out-effect"
                          } position-absolute d-dsk`}
                        >
                          <img src={item.image.url} alt="Hero" width="742px" />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  )
}

export default AccordionV3
