import gsap from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import React, { useEffect } from "react"
import "./StackCards.scss"

const StackCards = ({ data }) => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

    const allCardsEF = gsap.utils.toArray(".custom-card")

    const matchMedia = gsap.matchMedia()

    matchMedia.add("(min-width: 600px)", () =>{
      allCardsEF.forEach((card, index) => {
        if (index) {
          let scaleAnim = gsap.timeline().to(allCardsEF[index - 1], {
            scale: 0.75,
            y: 15,
            duration: 10,
          })
  
          ScrollTrigger.create({
            trigger: card,
            start: "top-=20px 440px",
            end: "bottom 440px",
            animation: scaleAnim,
            scrub: 0.8,
          })
  
          let opacAnim = gsap
            .timeline()
            .from(card, { opacity: 0, duration: 10 })
            .to(card, {
              opacity: 1,
              duration: 20,
              delay: 1,
            })
  
          ScrollTrigger.create({
            trigger: card,
            start: "top bottom-=25%",
            end: "bottom+=150px 200px",
            animation: opacAnim,
            scrub: 1,
          })
        }
      })
    })

  }, [])

  return (
    <div className="stack-cards">
      <div className="container">
        <div className="cards prelative">
          {data.text.map((el, idx) => {
            return (
              <div
                className={`custom-card`}
              >
                <div className="altv1">
                  <div
                    className="icon-wrapper mb30"
                    style={{ background: `${data.links[idx]}` }}
                  >
                    <img src={data.image[idx].url} alt="icon" className="w-100" />
                  </div>
                  <h5 className="font-roboto fw-bold mb16">{el}</h5>
                  <p className="p16 m-0">{data.subtext[idx]}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default StackCards
